<template>
  <div :style="{ direction: $vs.rtl ? 'rtl' : 'ltr' }">
    <feather-icon @click="editRecord" icon="Edit3Icon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" />
  </div>
</template>

<script>
export default {
  methods: {
    editRecord() {
      this.$router.push(`banks/${this.params.data.id}`).catch(() => {});
    },
    showError(exception) {
      this.$vs.loading.close();

      let error = 'An unknown error occurred while archive this bank';
      if (exception.response) {
        error = exception.response.data.message;
      }

      return this.$vs.notify({
        title: 'Failed to archive bank',
        text: error,
        color: 'danger',
        position: 'top-right',
      });
    },
  },
};
</script>
